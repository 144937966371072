// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-ganen-js": () => import("./../../../src/pages/about-ganen.js" /* webpackChunkName: "component---src-pages-about-ganen-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-home-automation-js": () => import("./../../../src/pages/home-automation.js" /* webpackChunkName: "component---src-pages-home-automation-js" */),
  "component---src-pages-home-job-references-js": () => import("./../../../src/pages/home-job-references.js" /* webpackChunkName: "component---src-pages-home-job-references-js" */),
  "component---src-pages-home-lighting-js": () => import("./../../../src/pages/home-lighting.js" /* webpackChunkName: "component---src-pages-home-lighting-js" */),
  "component---src-pages-home-security-js": () => import("./../../../src/pages/home-security.js" /* webpackChunkName: "component---src-pages-home-security-js" */),
  "component---src-pages-hoteliers-and-property-developers-js": () => import("./../../../src/pages/hoteliers-and-property-developers.js" /* webpackChunkName: "component---src-pages-hoteliers-and-property-developers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-office-job-references-js": () => import("./../../../src/pages/office-job-references.js" /* webpackChunkName: "component---src-pages-office-job-references-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-sub-categories-js": () => import("./../../../src/templates/sub-categories.js" /* webpackChunkName: "component---src-templates-sub-categories-js" */),
  "component---src-templates-sub-category-products-js": () => import("./../../../src/templates/sub-category-products.js" /* webpackChunkName: "component---src-templates-sub-category-products-js" */)
}

